import React from "react"
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import List from "../components/List"
import LoadingIcon from "../components/loadingIcon"
import SEO from "../components/seo"

const Projects = ({ data }) => {
  const projects = data.allApiProject.nodes;

  return (
    <Layout>
      <SEO title="Projects" />
      <div className="our-causes pt-0">
        <div className="container">
          <h1>Projects</h1>
          <div className="row">
            {!projects ? (
              <>
                Loading projects...
                <br />
                <LoadingIcon />
              </>
            ) : (
              <List data={projects} itemType="projects" />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Projects

export const pageQuery = graphql`
	query ProjectsQuery {
    allApiProject {
      nodes {
        id
        name
        promotionLine
        standardImage
        showInsteadofAmount
        thumbnailImage
      }
    }
	}
`;