import React from "react"
import { Link } from "gatsby"

const ProjectItem = ({ data }) => {
  const { id, name, thumbnailImage, promotionLine, showInsteadofAmount } = data
  const url = "/projects/" + id
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="cause-wrap">
        <figure className="m-0">
          <img src={thumbnailImage} alt={name} />
          <div className="figure-overlay d-flex justify-content-center align-items-center position-absolute w-100 h-100">
            <a href="/donate" className="btn gradient-bg mr-2">
              Donate Now
            </a>
          </div>
        </figure>
        <div className="cause-content-wrap">
          <header className="entry-header d-flex flex-wrap align-items-center">
            <h3 className="entry-title w-100 m-0">
              <Link to={url}>{name}</Link>
            </h3>
          </header>

          <div className="entry-content">
            <p className="m-0">{promotionLine}</p>
          </div>

          <div className="fund-raised w-100">
            {/* <div className="fund-raised-bar-1 barfiller">
              <div className="tipWrap" style={{ display: "inline" }}>
                <span
                  className="tip"
                  style={{
                    left: "193.72px",
                    transition: "left 1.5s ease-in-out 0s",
                  }}
                >
                  83%
                </span>
              </div>

              <span
                className="fill"
                data-percentage={83}
                style={{
                  background: "rgb(255, 90, 0)",
                  width: "235.72px",
                  transition: "width 1.5s ease-in-out 0s",
                }}
              />
            </div> */}

            <div className="fund-raised-details d-flex flex-wrap justify-content-between align-items-center">
              <div className="fund-raised-total mt-4"></div>

              <div className="fund-raised-goal mt-4">
                Goal: {showInsteadofAmount}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectItem
