import React from "react"
import ProjectItem from "./components/ProjectItem"

const List = ({ data, itemType }) => {
  const components = {
    projects: ProjectItem,
  }

  const ItemType = components[itemType]

  const renderItem = data.map(item => {
    if (typeof ItemType !== "undefined") {
      return <ItemType key={item.id ? item.id : item.node.id} data={item} />
    }
    return null
  })

  return renderItem
}

export default List
